<template>
  <div class="good-riddance-feature">
    <div class="feature-content">
      <div
        class="content-left"
        :style="{ backgroundImage: `url(${useCdnUrl()}/assets/good-riddance/gr-left.webp)` }"
      >
        <div class="message">
          <h2>Good Riddance Day 2024</h2>

          <div>
            <p>Powered by the Zenapptic</p>
            <h3>Zen3 <span>Platform</span></h3>
          </div>

          <div class="link">
            <span
              ><img :src="`${useCdnUrl()}/assets/vnye-2025/left-pointer.webp`" alt="VNYE 2025"
            /></span>
            <a
              :href="`${useCdnUrl()}/assets/good-riddance/Zenapptic_Good_Riddance_Day_PR.pdf`"
              target="_blank"
              >More Info <span class="sr-only">- download full press release</span></a
            >
            <span
              ><img :src="`${useCdnUrl()}/assets/vnye-2025/right-pointer.webp`" alt="VNYE 2025"
            /></span>
          </div>
        </div>
      </div>
      <div
        class="content-right"
        :style="{ backgroundImage: `url(${useCdnUrl()}/assets/good-riddance/gr-right.webp)` }"
      />
    </div>
  </div>
</template>

<style lang="scss">
.good-riddance-feature {
  padding: 0;

  .feature-content {
    display: flex;
    flex-direction: row;
    min-height: 90vh;

    .content-left {
      align-items: center;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      border-right: 4px black solid;
      display: flex;
      flex: 4;
      padding: 0;

      .message {
        background-color: rgba(0, 0, 0, 0.6);
        color: rgb(241, 218, 158);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 5rem 2rem;
        text-align: right;
        width: 100%;

        h2 {
          font-size: clamp(3rem, 3.5vw, 7.4rem);
          font-weight: 700;
          letter-spacing: -2px;
          line-height: 0.85;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
        }

        div {
          align-items: flex-start;
          display: flex;
          gap: 2rem;
          justify-content: flex-end;

          @media (max-width: 850px) {
            align-items: flex-end;
            flex-direction: column;
            gap: 1rem;
          }

          p,
          h3 {
            margin: 0;
            padding: 0;
            text-transform: uppercase;
          }

          h3 {
            font-size: clamp(3rem, 3vw, 7.4rem);
            font-weight: 700;

            letter-spacing: -2px;
            line-height: 0.85;

            span {
              font-weight: 200;
            }
          }

          p {
            font-size: 2rem;
          }
        }

        .link {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 1.5rem;
          a {
            color: white;
            font-size: clamp(2rem, 2vw, 3rem);
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;

            span {
              color: white;
            }
          }
        }
      }
    }
    .content-right {
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      flex: 2;
      padding: 0;
    }
  }
}
</style>
