<script setup lang="ts">
import { features } from "@/static/features";
import type { FeatureItemData } from "~/types";

const featureItems = ref<FeatureItemData[] | null>(features);

const slideCount = ref(0);
const previousCount = ref(features.length - 1);

onMounted((): void => {
  const slideId = featureItems.value![0].id;
  const { sendScreenView } = useAnalytics();
  sendScreenView({
    type: "FeatureSlider.SlideView",
    target: slideId,
  });
});
</script>

<template>
  <div class="feature-block">
    <div class="container">
      <FeatureItem
        v-for="(item, index) in featureItems"
        :id="index"
        :key="index"
        ref="`feature${index}`"
        :class="{
          active: slideCount == index,
          'active-out': previousCount == index,
        }"
        :data="item"
        :index="index + 1"
        :is-active="slideCount == index"
      />
    </div>
  </div>
</template>

<style lang="scss">
.feature-block {
  overflow: hidden;
  position: relative;
  width: 100%;

  .container {
    height: calc(100vh - 60px - 30px);
    margin: 89px auto 0 auto;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 768px) {
      height: calc(100vh - 63px - 90px);
      margin-top: 63px;
    }
  }
}
</style>
