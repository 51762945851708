<template>
  <div class="home-section solutions-block">
    <div class="container">
      <div class="arrow-dots">
        <img :src="`${useCdnUrl()}/assets/icons/arrow-dots.png`" alt="" />
      </div>
      <div class="body">
        <div class="title">
          <img
            :src="`${useCdnUrl()}/assets/solutions/solutions-title.webp`"
            alt="Zenapptic Solutions"
          />
          <h2>Zenapptic <b>Solutions</b></h2>
        </div>
        <div class="items">
          <ul>
            <li>
              <div>
                <NuxtLink to="/solutions/experiential-spaces">
                  <img
                    :src="`${useCdnUrl()}/assets/solutions/experiential-spaces.webp`"
                    alt="experiential spaces"
                  />

                  <div class="item-list">
                    <h3>Experiential<br />Spaces</h3>
                    <ul class="blue">
                      <li>IMMERSIVE LOBBIES</li>
                      <li>IMMERSIVE RETAIL EXPERIENCES</li>
                    </ul>

                    <!-- <NuxtLink
                      to="/solutions/experiential-spaces"
                      class="user-action"
                    >
                      Learn more
                    </NuxtLink> -->
                  </div>
                </NuxtLink>
              </div>
            </li>

            <li>
              <div>
                <NuxtLink to="/solutions/digital-workplace">
                  <img
                    :src="`${useCdnUrl()}/assets/solutions/digital-workplace-solutions.webp`"
                    alt="digital workplace solutions"
                  />

                  <div class="item-list">
                    <h3>Digital<br />Workplace<br />Solutions</h3>
                    <ul class="green">
                      <li>COMMAND CENTERS</li>
                      <li>SMART FACTORIES</li>
                      <li>REAL-TIME DASHBOARDS</li>
                    </ul>
                  </div>
                </NuxtLink>
              </div>
            </li>

            <li>
              <div>
                <NuxtLink to="/solutions/experience-centers">
                  <img
                    :src="`${useCdnUrl()}/assets/solutions/experience-centers.webp`"
                    alt="experience centers"
                  />

                  <div class="item-list">
                    <h3>Experience<br />Centers</h3>
                    <ul class="red">
                      <li>MUSEUMS</li>
                      <li>INTERACTIVE BRAND EXPERIENCES</li>
                      <li>CUSTOMER EXPERIENCE CENTERS</li>
                    </ul>
                  </div>
                </NuxtLink>
              </div>
            </li>

            <li>
              <div>
                <NuxtLink to="/solutions/virtual-production">
                  <img
                    :src="`${useCdnUrl()}/assets/solutions/virtual-production.webp`"
                    alt="virtual production"
                  />

                  <div class="item-list">
                    <h3>Virtual<br />Production</h3>
                    <ul class="yellow">
                      <li>LIVE PRODUCTIONS</li>
                      <li>INTEGRATION WITH ZOOM OR TEAMS</li>
                      <li>CORPORATE PRESENTATIONS</li>
                      <li>LIVE EVENTS</li>
                    </ul>
                  </div>
                </NuxtLink>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="arrow-dots">
        <img :src="`${useCdnUrl()}/assets/icons/arrow-dots.png`" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.solutions-block {
  background: #f0f0e6;
  color: #fff;
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 10rem 0 !important;

    .arrow-dots {
      text-align: center;
    }

    .body {
      background: #f0f0e6;
      display: inline-flex !important;
      gap: 0;
      grid-template-columns: repeat(2, 1fr);
      position: relative;

      .title {
        img {
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }

        h2 {
          color: black;
          @media only screen and (min-width: 768px) {
            display: none;
          }
        }
      }

      .items {
        border-bottom: dotted black 1px;
        border-left: 40px solid #6734bb;
        flex: 1;

        @media only screen and (max-width: 767px) {
          border: none;
        }

        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: 100%;
          list-style-type: none;
          margin: 0;
          padding: 0;

          > li {
            border-right: dotted black 1px;
            flex: 1;

            a {
              text-decoration: none;
            }

            @media only screen and (max-width: 820px) {
              flex: 50%;
            }

            img {
              height: 250px;
              object-fit: cover;
              width: 100%;
            }

            h3 {
              color: black;
              font-size: 2.75rem;
              font-weight: 300;
              margin: 0;
              padding: 0 1.5rem;
              text-transform: uppercase;
            }

            .item-list {
              padding: 1rem 0;
              h3 {
                font-weight: 800;
                height: 130px;
                line-height: 1;

                @media only screen and (max-width: 820px) {
                  height: 100px;
                }

                @media only screen and (max-width: 767px) {
                  height: auto;
                }
              }

              .user-action {
                color: #6734bb;
                padding-left: 1.5rem;
                text-decoration: underline;
              }

              ul {
                border-top: dotted black 1px;
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 0;
                gap: 2rem;
                padding: 1.5rem;
                position: relative;

                li {
                  border: none;
                  color: black;
                  font-size: 2.6rem;
                  font-weight: 200;
                  line-height: 1;
                }
              }
            }
          }
        }
      }

      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        h2 {
          position: relative;
          transform: rotate(0deg);
        }

        .items {
          padding-left: 0;
          ul {
            li {
              div {
                h3 {
                  padding-bottom: 2rem;
                }

                .item-list {
                  flex-direction: column;
                  gap: 2rem;

                  ul {
                    gap: 1rem;
                    &:before {
                      top: 0;
                      transform: translateY(0);
                    }
                  }

                  img {
                    height: 165px;
                    margin: auto;
                    max-width: 300px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .body {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    @media only screen and (device-width: 768px) {
      .body {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media only screen and (min-width: 769px) {
      .body {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
</style>
