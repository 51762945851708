<script setup lang="ts">
import { watchEffect } from "vue";
import type { FeatureItemData } from "~/types";

interface FeatureItemProps {
  index: number;
  data: FeatureItemData;
  isActive: boolean;
}
const props = defineProps<FeatureItemProps>();

const emit = defineEmits(["closeSlide"]);

const defaultDuration = 10000;
let timer: any | null;

const mainElement = ref<HTMLElement>();
const videoPlayer = ref<HTMLVideoElement>();

const { sendScreenView } = useAnalytics();
let itemObserver: IntersectionObserver | null = null;

onMounted((): void => {
  // Need to watch if slide was changed outside of this component and it resets
  watchEffect(() => {
    if (props.isActive && props.data.video && videoPlayer.value) {
      videoPlayer.value.currentTime = 0;
      videoPlayer.value.play();
      sendScreenView({
        type: "Video.play",
        target: "ZenDemoreel",
      });
    }

    if (!props.isActive && props.data.video && videoPlayer.value) {
      videoPlayer.value.pause();
    }

    if (props.isActive && !timer && !props.data.video) {
      startTimeOut();
    } else if (!props.isActive && timer) {
      cancelTimeout(true);
    }
  });

  document.addEventListener("visibilitychange", () => {
    if (
      document.visibilityState === "visible" &&
      props.isActive &&
      props.data.video &&
      videoPlayer.value
    ) {
      videoPlayer.value.play();
    } else if (props.data.video && videoPlayer.value) {
      videoPlayer.value.pause();
    }
  });

  itemObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && props.isActive && !timer) {
        if (props.data.video && videoPlayer.value) {
          videoPlayer.value.play();
        } else {
          startTimeOut();
        }
      } else {
        if (props.data.video && videoPlayer.value) {
          videoPlayer.value.pause();
        }
        cancelTimeout(true);
      }
    });
  });

  itemObserver.observe(mainElement.value!);
});

function startTimeOut(): void {
  let toDuration = defaultDuration;

  if (props.data.duration) {
    toDuration = props.data.duration * 1000;
  }

  if (timer) {
    clearTimeout(timer);
    timer = null;
  }

  timer = setTimeout(() => {
    cancelTimeout();
  }, toDuration);
}

function cancelTimeout(bypassEvent = false): void {
  if (timer) {
    clearTimeout(timer);
    timer = null;

    if (!bypassEvent) {
      emit("closeSlide");
    }
  }
}

function videoEndedHandler(): void {
  if (props.isActive) {
    sendScreenView({
      type: "Video.playbackComplete",
      target: "ZenDemoreel",
    });
    emit("closeSlide");
  }
}
</script>

<template>
  <div ref="mainElement" class="feature-item">
    <div v-if="data.video" class="video-content">
      <video
        ref="videoPlayer"
        :src="`${useCdnUrl()}${data.video.url}`"
        type="video/mp4"
        :poster="`${useCdnUrl()}${data.video.poster}`"
        autoplay="true"
        playsinline
        muted
        loop
        @ended="videoEndedHandler"
      />
    </div>

    <div v-if="props.data.h1 !== ''" class="feature-content">
      <article class="left-top">
        <div class="feature-title">
          <h2>{{ props.data.h1 }}</h2>
        </div>
      </article>
    </div>
  </div>
</template>

<style lang="scss">
.feature-item {
  align-content: end;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  .video-content {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    video {
      height: 100%;
      width: 100%;

      @media only screen and (min-width: 768px) {
        position: absolute;
        top: 0;
      }
    }
  }

  &.active {
    left: 0 !important;
    transition: all 1s ease-out;
    z-index: 10;
  }

  .feature-content {
    bottom: 0;
    margin: auto;
    max-width: 1366px;
    position: absolute;
    width: 100%;
    z-index: 1;

    &.virtual-experience-override {
      margin-bottom: 13.4rem;
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      margin-bottom: 0;

      &.virtual-experience-override {
        margin-bottom: 0;
      }
    }

    article {
      display: block;
      width: 100%;

      .feature-title,
      .feature-body {
        background: rgba(10, 11, 12, 0.75);
      }

      .feature-title {
        padding: 2rem;

        @media only screen and (max-width: 768px) {
          padding: 1rem 2rem;
          text-align: left;
        }

        .feature-divider {
          align-items: center;
          display: grid;
          grid-template-columns: auto 30px;

          @media only screen and (max-width: 768px) {
            margin-bottom: 1rem;
          }

          .divider {
            background: rgba(255, 255, 255, 0.25);
            height: 1px;
          }
        }

        h1,
        h2,
        h3 {
          margin: 0;
          padding: 0;
          text-transform: uppercase;
        }

        h2 {
          color: #f0f0e6;
          font-size: min(9vw, 6rem);
          font-weight: 500;
          line-height: 1;
          margin-bottom: 30px;
        }

        h3 {
          color: #fff;
          font-size: min(9vw, 5rem);
          font-weight: 400;
          line-height: 1;
        }
      }

      .feature-tag-top {
        background: #f0f0e6;
        color: #fff;
        justify-self: end;
        padding: 0.5rem;
      }

      .feature-body {
        color: #fff;
        font-size: min(4.5vw, 2.2rem);
        padding: 2rem;

        @media only screen and (max-width: 768px) {
          padding: 1rem 2rem;
        }

        span {
          color: #f0f0e6;
        }
      }

      .cta {
        a {
          background-position: 8px center;
          background-size: 5px 5px;
          border-bottom: 3px solid #f0f0e6;
          color: white;
          display: inline-block;
          font-size: 1.75rem;
          padding: 1rem 1rem 1rem 2rem;
          text-decoration: none;

          transition: all 0.2s ease-out;

          @media only screen and (max-width: 768px) {
            background-color: rgba(0, 0, 0, 0.8);
          }

          &:hover {
            background-color: #f0f0e6;
          }
        }
      }
    }
  }
}
</style>
