<script setup lang="ts">
const dots = computed(() => {
  return `url(${useCdnUrl()}/assets/dots.png) no-repeat left 1.3rem`;
});
</script>

<template>
  <div
    class="home-section about-block"
    :style="{
      backgroundImage: `linear-gradient(90deg, rgba(26, 23, 25, 1) 50%, rgba(35, 37, 49, 0) 50%), url(${useCdnUrl()}/assets/single-dot.png)`,
    }"
  >
    <div class="container">
      <h2>About <b>Us</b></h2>

      <div class="body">
        <p>
          At <b>Zenapptic<span class="gray">.AI</span></b
          >, we believe in the transformative power of immersive experiences.
        </p>

        <p>
          Our <b>ZEN3</b> platform seamlessly blends technology and creativity to unlock new
          dimensions of real-time interactivity and storytelling. With a commitment to pushing the
          boundaries of what's possible, we invite you to share your ideas and work with us to craft
          an unforgettable visual experience.
        </p>
        <p>
          Discover how Zenapptic.AI can elevate your digital presence and redefine the art of
          engagement. Welcome to the future of truly immersive experiences.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.about-block {
  color: #fff;
  .container {
    h2 {
      font-weight: 200 !important;
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: center;

      .gray {
        color: gray;
      }

      @media only screen and (max-width: 768px) {
        display: block;
      }

      p {
        margin: 0;
        padding: 0;
        padding-left: 1.3rem;
      }

      p:first-child {
        background: v-bind(dots);
      }
    }

    .body-action {
      padding-top: 3rem;
      text-align: center;
    }
  }
}
</style>
