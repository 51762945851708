<script setup lang="ts">
import { computed } from "vue";

const getBgroundLg = computed(() => `url(${useCdnUrl()}/assets/nab-2025/bg-main.webp)`);
const getBgroundSm = computed(() => `url(${useCdnUrl()}/assets/nab-2025/bg-main-mobile.webp)`);
</script>

<template>
  <div class="nab-2025-feature">
    <div class="nab-2025-content">
      <div
        class="content-left"
        :style="{ backgroundImage: `url(${useCdnUrl()}/assets/nab-2025/bg-header.webp)` }"
      >
        <h2><b>ZEN3 MEDIA DIRECTOR:</b></h2>
        <h2 class="title-right">ANY CONTENT TO ANY SCREEN</h2>
        <div class="cta">
          <a :href="`${useCdnUrl()}/assets/nab-2025/ZEN3_Media_Director.pdf`" target="_blank"
            >Read Press Release</a
          >
        </div>
      </div>

      <div
        class="content-right"
        :style="{ backgroundImage: `url(${useCdnUrl()}/assets/nab-2025/bg-header.webp)` }"
      >
        <ul>
          <li>
            <img
              :src="`${useCdnUrl()}/assets/logos/zen-2023-header.svg`"
              alt="Zenapptic.ai"
              class="zen-img"
            />
          </li>
          <li>
            <img :src="`${useCdnUrl()}/assets/nab-2025/unilumin-logo.webp`" alt="Unilumin" />
          </li>

          <li class="cta-block">
            <div class="location">
              <div class="location-left">Visit us <span>> > > > ></span></div>
              <div class="location-right">Booth #N3139</div>
            </div>
            <div class="cta">
              <a href="#contact">Book a meeting</a>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="nab-2025-footer"
      :style="{ backgroundImage: `url(${useCdnUrl()}/assets/nab-2025/bg-footer.webp)` }"
    >
      <ul>
        <li>April 5 - 9</li>
        <li>Las Vegas Convention Center (LVCC)</li>
        <li>Las Vegas</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nab-2025-feature {
  width: 100%;
  max-width: 1130px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  background-image: v-bind(getBgroundLg);
  @media (max-width: 860px) {
    background-size: 100%;
    background-image: v-bind(getBgroundSm);
  }

  .nab-2025-content {
    display: flex;

    @media (max-width: 860px) {
      flex-direction: column;
    }

    > div {
      width: 50%;
      min-height: 100px;
      background-size: 200% auto;
      background-repeat: no-repeat;

      @media (max-width: 860px) {
        width: 100%;
      }

      &.content-left {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        padding: 3rem 2rem;

        h2 {
          font-size: 3rem;
          font-weight: 200;
          color: white;
          padding: 0;
          margin: 0;
          width: 100%;
          text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.7);
          .title-right {
            text-align: right;
          }

          @media (max-width: 860px) {
            text-align: center;
            .title-right {
              text-align: center !important;
            }
          }
        }

        .cta {
          padding-top: 2rem;
          @media (max-width: 860px) {
            text-align: center !important;
            width: 100%;
          }
        }

        a {
          color: white;
          text-decoration: none;
          font-size: 1.5rem;
          font-weight: 900;
          background: #f1139c;
          border: 1px solid #f1139c;
          padding: 0.75rem 1.5rem;
          border-radius: 3px;
          text-decoration: none;
          transition: background 0.3s ease-out;
          &:hover {
            background: transparent;
          }
        }

        @media (max-width: 860px) {
          height: 750px;
        }

        @media (max-width: 650px) {
          height: 600px;
        }

        @media (max-width: 500px) {
          height: 400px;
        }
      }

      &.content-right {
        background-position: top right;

        padding-top: 100px;
        background-color: rgba(36, 30, 66, 0.7);
        backdrop-filter: blur(10px);
        height: 600px;

        @media (max-width: 1105px) {
          height: 100%;
          padding-top: 91px;
        }

        @media (max-width: 860px) {
          background-size: 0;
          height: 100%;
          padding-top: 0;
        }

        @media (max-width: 768px) {
          height: 100%;
        }

        ul {
          border-left: dotted 1px white;
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: grid;
          grid-template-rows: 1fr 1fr 1fr;
          height: 100%;

          @media (max-width: 1105px) {
            height: auto;
            display: flex;
            flex-direction: column;
          }

          li {
            padding: 2rem;
            text-align: center;
            border-bottom: dotted 1px white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
              border-bottom: none;
            }

            img {
              width: 60%;

              @media (max-width: 1105px) {
                width: 50%;
              }

              @media (max-width: 768px) {
                width: auto;
                height: 40px;
              }

              &.zen-img {
                width: 80%;

                @media (max-width: 1105px) {
                  width: 60%;
                }

                @media (max-width: 768px) {
                  width: auto;
                  height: 60px;
                }
              }
            }

            &.cta-block {
              flex-direction: column;
              gap: 3rem;
              padding-left: 5rem;
              padding-right: 5rem;

              .location {
                display: flex;
                justify-content: space-between;
                width: 100%;

                @media (max-width: 1105px) {
                  flex-direction: column;
                  justify-content: center;
                }

                .location-left {
                  font-size: 2.25rem;
                  font-weight: 700;
                  color: #f1139c;
                  display: flex;
                  align-items: center;
                  gap: 1.75rem;

                  @media (max-width: 1105px) {
                    font-size: 1.75rem;
                  }

                  span {
                    padding-top: 0.25rem;
                    font-size: 1rem;
                  }
                }

                .location-right {
                  font-size: 3.8rem;
                  font-weight: 700;
                  color: #8c7ff4;

                  @media (max-width: 1105px) {
                    font-size: 3rem;
                  }
                }
              }

              .cta {
                width: 100%;
                text-align: right;

                @media (max-width: 1105px) {
                  text-align: center;
                  padding-bottom: 1rem;
                }

                a {
                  color: white;
                  text-decoration: none;
                  font-size: 1.5rem;
                  font-weight: 900;
                  background: #f1139c;
                  border: 1px solid #f1139c;
                  padding: 0.75rem 1.5rem;
                  border-radius: 3px;
                  text-decoration: none;
                  transition: background 0.3s ease-out;
                  &:hover {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .nab-2025-footer {
    background-size: 100% 100%;
    font-size: 1.8rem;
    color: white;
    font-weight: 900;
    display: flex;
    justify-content: flex-end;
    padding: 3rem 3rem 2rem 3rem;
    background-repeat: no-repeat;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      li {
        padding: 0.5rem 2rem;
        border-right: 1px solid white;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
</style>
